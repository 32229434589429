import { DOMAIN } from "./utils/domain";

export default {
  titleTemplate: '%s | RifugiAmiata.it',
  defaultTitle: '',
  openGraph: {
    type: 'website',
    locale: 'it_IT',
    url: "https://" + DOMAIN,
    site_name: 'RifugiAmiata.it',
  },
};

