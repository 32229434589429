import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// Create a theme instance.
let theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      hyml: {
          a: {
            color: '#00AE8D',
            fontWeight: '900',
          },
            "a:hover": {
          },
          body: {
            fontSize: '0.875rem',
            lineHeight: 1.43,
            letterSpacing: '0.01071em',
          },          
        }
      `
    },
  },
  palette: {
    primary: {
      main: "#00AE8D",
    },
    muted: {
      main: "#00ae8d",
    },
    secondary: {
      main: "#dd7377",
    },
  },
  color: "white",
  typography: {
    body1: {
      fontSize: "18px",
    },
    body2: {
      fontSize: '1.05em',
      fontWeight: "600",
    },
    body3: {
      fontSize: '0.8em',
      fontWeight: "400",
    },
    h4: {
      fontWeight: "900",
      color: "#00AE8D",
      fontSize: "2rem",
      '@media (min-width:600px)': {
        fontSize: '3rem',
      },
    },    
    h6: {
      fontSize: "1.31rem",
    },
    h3: {
      fontWeight: "600",
      color: "#00AE8D",
      fontSize: "1.5rem",
      paddingTop: "10px",
      lineHeight: "1.13333em",
      '@media (min-width:600px)': {
        fontSize: '1.8rem',
      },      
    },
    h5: {
      fontWeight: "bold",
      color: "#00AE8D",
    },
    textSecondary: {
      main: "blue",
    },
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  },	
});

export default theme;


