var DOMAIN = "rifugiamiata.it";
var DOMAIN_URL = `https://${DOMAIN}`;
var API_DOMAIM = DOMAIN;
var DOMAINS_API_URL = `https://${API_DOMAIM}`;

if (process.env.NEXT_PUBLIC_VERCEL_ENV == 'development') {
    DOMAIN = "localhost:3000";
    DOMAIN_URL = `http://${DOMAIN}`;
    API_DOMAIM = DOMAIN;
    DOMAINS_API_URL = `http://${API_DOMAIM}`;
} else if (process.env.VERCEL_ENV == 'preview') {
    DOMAIN = process.env.VERCEL_URL;
    DOMAIN_URL = `https://${DOMAIN}`;
    API_DOMAIM = DOMAIN;
    DOMAINS_API_URL = `https://${API_DOMAIM}`;
}

export {DOMAIN, DOMAIN_URL, DOMAINS_API_URL};
